<template>
  <div class="form-container" v-show="show" @click="hidePopup">
    <div class="form-modal">
      <div class="flex flex-row justify-content-between">
        <div><img src="/img/logo-300_217.webp" alt="Blockproof" height="55px"/></div>
        <div><i class="pi pi-times" style="cursor: pointer;" @click="$emitter.emit('openAlertEvent', {show: false})"></i></div>
      </div>
      <div style="text-align: center; padding: 24px 0">{{ message }}</div>
    </div>
  </div>
</template>


<script setup lang="ts">
import {useNuxtApp} from '#imports';
import {ref} from '@vue/reactivity';

let show = ref(false);
let message = ref('');

const hidePopup = () => show.value = false;

// ## Event
const {$emitter} = useNuxtApp();

interface OpenAlertEvent {
  show: boolean
  message?: string
}

$emitter.on('openAlertEvent', (event: OpenAlertEvent) => {
  show.value = event.show;
  message.value = event.message || '';
})
</script>

<style scoped>

</style>
