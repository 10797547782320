<template>
  <!-- Google Tag Manager (noscript) -->
  <noscript>
    <iframe :src="'https://www.googletagmanager.com/ns.html?id='+ GTM_MEASUREMENT_ID"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>
  </noscript>
  <!-- End Google Tag Manager (noscript) -->
</template>


<script setup lang="ts">
import {useRuntimeConfig} from '#app';

const runtimeConfig = useRuntimeConfig();
const GTM_MEASUREMENT_ID = runtimeConfig.public.GTM_MEASUREMENT_ID;
</script>
